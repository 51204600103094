/* This component renders all the Open Resources sub categories */
import React from 'react'
import { Router } from '@reach/router'
import { Link, useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'

const urls = {
  church: { categoryName: 'Church', configID: '4dzGJGhZpcMKqNzFa3PGXa' },
  'disability-mission': {
    categoryName: 'Disability in Mission',
    configID: '6LuqySmVgPwENr8NQpPLBD'
  },
  indigenous: { categoryName: 'Indigenous', configID: '2ODuULSOnM0Tbou0ZgAntY' },
  'member-care': { categoryName: 'Member Care', configID: '1rOtDaRJMvKTynWv90pizT' },
  mission: { categoryName: 'Mission', configID: '1Qimv5rEExhOzypgVjz9aJ' },
  'not-for-profit': { categoryName: 'Not for Profit', configID: '56wZ5akWnvGnpoDWrvHZzC' },
  prayer: { categoryName: 'Prayer', configID: '2BaTOmrccPIeyb6Fr8yKcY' },
  policies: {categoryName: 'Policies', configID: '4xVPdzUynEu8LRPCzTwTJL'},
  'research-and-study': { categoryName: 'Research & Study', configID: '32ElMsfvAYOZ5rbYAw1ZqI' },
  'tck-and-mk': { categoryName: 'TCK & MK', configID: '4AeTZNWJY4g942ICPDWxt3' },
  transport: { categoryName: 'Transport', configID: '15rr4WxiyGn8W7hYk3RHjd' }
}

const OpenResourceCategory = ({ path, data, category }) => {
  const { categoryName, configID } = urls[category]

  const TYPE = 'Resource'
  const IMAGE_HEIGHT = 150
  const filteredData = data
    .filter(item => item.node.category === categoryName)
    .map(obj => ({
      id: obj.node.id,
      title: obj.node.title,
      body: obj.node.excerpt,
      image: obj.node.image,
      imageHeight: IMAGE_HEIGHT,
      slug: `/resources/${obj.node.slug}`,
      category: null,
      type: TYPE
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <Link to="/resources" className="back-link">
        &larr; Resources
      </Link>
      <List data={filteredData} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

const Open = ({ location }) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulResources(
        sort: { fields: category, order: ASC }
        filter: { resourcestore: { ne: true } }
      ) {
        edges {
          node {
            id
            category
            title
            slug
            excerpt
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = queryData.allContentfulResources.edges

  return (
    <Router>
      <OpenResourceCategory data={data} path="/resources/open/:category" />
    </Router>
  )
}

export default Open
